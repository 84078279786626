<template>
    <div class="relative">
        <figure :class="{ 'portrait': isPortrait }">
            <img @click="handleClick" :src="src">
            <figcaption>{{ caption }}</figcaption>
        </figure>
    </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'
import Modal from "@/components/Modal"

import { openInNewWindow } from "@/utils/url";

export default {
    name: 'ImageBlock',
    components: {
        LoadingIndicator,
        Modal,
    },
    props: {
        block: Object
    },
    data() {
        return {
            isModalOpen: false,
        }
    },
    computed: {
        src() {
            let src = '';
            if (this.block.value.image.medium) {
                src = this.block.value.image.medium;
            } else if (this.block.value.image.url) {
                src = this.block.value.image.url;
            }
            return src;
        },

        caption() {
            let caption = ''
            if (this.block.value.image_caption) {
                caption = this.block.value.image_caption
            } else if (this.block.value.image.caption) {
                caption = this.block.value.image.caption
            }
            return caption;
        },
        isPortrait() {
            const image = this.block.value.image;
            return image.orientation === 'portrait' && image.height >= 700;
        },
    },
    methods: {
        urlOriginal(image) {
            if (image.error) return '';
            return image.original_url;
        },
        handleClick() {
            const id = this.block.value.image.id;
            const href = `/image-viewer?image_id=${id}`;

            openInNewWindow(href);
            // openInNewWindow(this.block.value.image.original_url);
        },
    },
}
</script>

<style lang="scss" scoped>
figure {
    margin: 0;
    padding: 0;

    &:hover {
        cursor: pointer;
    }
}


img {
    max-width: 100%;
}

.portrait {
    width: 50%;
}
</style>
